import {useMatches} from '@remix-run/react';
import {extractWithPath} from '@sanity/mutator';
import type {
  Collection,
  Product,
  ProductOption,
  ProductVariant,
} from '@shopify/hydrogen/storefront-api-types';
import type {AppLoadContext} from '@shopify/remix-oxygen';
import {json, type LoaderArgs} from '@shopify/remix-oxygen';
import pluralize from 'pluralize-esm';
import {useMemo} from 'react';

import {countries} from '~/data/countries';
import type {SanityModule} from '~/lib/sanity';
import type {
  SanityCollectionPage,
  SanityHomePage,
  SanityPage,
  SanityProductPage,
} from '~/lib/sanity';
import {PRODUCTS_AND_COLLECTIONS} from '~/queries/shopify/product';
import type {I18nLocale} from '~/types/shopify';

/** @see https://github.com/sanity-io/sanity/pull/4461 */
const extract = (...args: Parameters<typeof extractWithPath>) =>
  extractWithPath(...args).map(({value}) => value);

export const DEFAULT_LOCALE: I18nLocale = Object.freeze({
  ...countries.default,
  pathPrefix: '',
});

export function getLocaleFromRequest(request: Request): I18nLocale {
  const url = new URL(request.url);
  const firstPathPart =
    '/' + url.pathname.substring(1).split('/')[0].toLowerCase();

  return countries[firstPathPart]
    ? {
        ...countries[firstPathPart],
        pathPrefix: firstPathPart,
      }
    : {
        ...countries['default'],
        pathPrefix: '',
      };
}

export function usePrefixPathWithLocale(path: string) {
  const [root] = useMatches();
  const selectedLocale = root.data?.selectedLocale ?? DEFAULT_LOCALE;

  return `${selectedLocale.pathPrefix}${
    path.startsWith('/') ? path : '/' + path
  }`;
}

export function validateLocale({
  params,
  context,
}: {
  context: LoaderArgs['context'];
  params: LoaderArgs['params'];
}) {
  const {language, country} = context.storefront.i18n;
  if (
    params.lang &&
    params.lang.toLowerCase() !== `${language}-${country}`.toLowerCase()
  ) {
    // If the lang URL param is defined, and it didn't match a valid localization,
    // then the lang param must be invalid, send to the 404 page
    throw notFound();
  }
}

/**
 * Errors can exist in an errors object, or nested in a data field.
 */
export function assertApiErrors(data: Record<string, any> | null | undefined) {
  const errorMessage = data?.customerUserErrors?.[0]?.message;
  if (errorMessage) {
    throw new Error(errorMessage);
  }
}

/**
 * Map Shopify order status to a human-readable string
 */
export function statusMessage(status: string) {
  const translations: Record<string, string> = {
    ATTEMPTED_DELIVERY: 'Attempted delivery',
    CANCELED: 'Canceled',
    CONFIRMED: 'Confirmed',
    DELIVERED: 'Delivered',
    FAILURE: 'Failure',
    FULFILLED: 'Fulfilled',
    IN_PROGRESS: 'In Progress',
    IN_TRANSIT: 'In transit',
    LABEL_PRINTED: 'Label printed',
    LABEL_PURCHASED: 'Label purchased',
    LABEL_VOIDED: 'Label voided',
    MARKED_AS_FULFILLED: 'Marked as fulfilled',
    NOT_DELIVERED: 'Not delivered',
    ON_HOLD: 'On Hold',
    OPEN: 'Open',
    OUT_FOR_DELIVERY: 'Out for delivery',
    PARTIALLY_FULFILLED: 'Partially Fulfilled',
    PENDING_FULFILLMENT: 'Pending',
    PICKED_UP: 'Displayed as Picked up',
    READY_FOR_PICKUP: 'Ready for pickup',
    RESTOCKED: 'Restocked',
    SCHEDULED: 'Scheduled',
    SUBMITTED: 'Submitted',
    UNFULFILLED: 'Unfulfilled',
  };
  try {
    return translations?.[status];
  } catch (error) {
    return status;
  }
}

/**
 * Combine products and modules into a single array, with modules inserted at
 * regular intervals.
 */
const MODULE_INTERVAL = 2;
const START_INDEX = 2;

export function combineProductsAndModules({
  modules,
  products,
}: {
  products: Product[];
  modules?: SanityModule[];
}) {
  let moduleIndex = 0;
  return products.reduce<(SanityModule | Product)[]>((acc, val, index) => {
    if (index >= START_INDEX && index % MODULE_INTERVAL === 0) {
      const nextModule = modules?.[moduleIndex];
      if (nextModule) {
        acc.push(nextModule);
        moduleIndex += 1;
      }
    }
    acc.push(val);
    return acc;
  }, []);
}

/**
 * Check if a product has multiple options, e.g. Color / Size / Title
 */

export const hasMultipleProductOptions = (options?: ProductOption[]) => {
  const firstOption = options?.[0];
  if (!firstOption) {
    return false;
  }

  return (
    firstOption.name !== 'Title' && firstOption.values[0] !== 'Default Title'
  );
};

/**
 * Get the product options as a string, e.g. "Color / Size / Title"
 */
export const getProductOptionString = (options?: ProductOption[]) => {
  return options
    ?.map(({name, values}) => pluralize(name, values.length, true))
    .join(' / ');
};

type StorefrontPayload = {
  products: Product[];
  collections: Collection[];
};

/**
 * Get data from Shopify for page components
 */
export async function fetchGids({
  page,
  context,
}: {
  page: SanityHomePage | SanityPage | SanityCollectionPage | SanityProductPage;
  context: AppLoadContext;
}) {
  const productGids = extract(`..[_type == "productWithVariant"].gid`, page);
  const collectionGids = extract(`..[_type == "collection"].gid`, page);

  const {products, collections} =
    await context.storefront.query<StorefrontPayload>(
      PRODUCTS_AND_COLLECTIONS,
      {
        variables: {
          ids: productGids,
          collectionIds: collectionGids,
        },
      },
    );

  return extract(`..[id?]`, [...products, ...collections]) as (
    | Product
    | Collection
    | ProductVariant
  )[];
}

// TODO: better typing?
export function useGid<
  T extends Product | Collection | ProductVariant | ProductVariant['image'],
>(gid?: string | null): T | null | undefined {
  const gids = useGids();

  if (!gid) {
    return null;
  }

  return gids.get(gid) as T;
}

export function useGids() {
  const matches = useMatches();

  // TODO: this doesnt' seem to actually memoize...
  return useMemo(() => {
    const gids = new Map<
      string,
      Product | Collection | ProductVariant | ProductVariant['image']
    >();

    for (const match of matches) {
      if (!match.data?.gids?.length) {
        continue;
      }

      for (const gid of match.data.gids) {
        if (gids.has(gid.id)) {
          continue;
        }

        gids.set(gid.id, gid);
      }
    }

    return gids;
  }, [matches]);
}

/**
 * A not found response. Sets the status code.
 */
export const notFound = (message = 'Not Found') =>
  new Response(message, {
    status: 404,
    statusText: 'Not Found',
  });

/**
 * A bad request response. Sets the status code and response body
 */
export const badRequest = <T>(data: T) =>
  json(data, {status: 400, statusText: 'Bad Request'});

/**
 * Validates that a url is local to the current request.
 */
export function isLocalPath(request: Request, url: string) {
  // Our domain, based on the current request path
  const currentUrl = new URL(request.url);

  // If url is relative, the 2nd argument will act as the base domain.
  const urlToCheck = new URL(url, currentUrl.origin);

  // If the origins don't match the slug is not on our domain.
  return currentUrl.origin === urlToCheck.origin;
}

export function getMaterialName(name: String, lang: String) {
  let finalString = '';
  let isInArray = false;
  let inArrayPos = null;

  let stringBefore = '';

  let searchString = String(name.trim());

  let checkPercent = searchString.includes('%');
  if (checkPercent === true) {
    let newSearchArray = searchString.split(' ');
    stringBefore = newSearchArray[0] + ' ';
    searchString = newSearchArray[1];
  }

  const arrayDE = [
    'Baumwolle',
    'Nylon',
    'Nylon Supplex',
    'Polyester',
    'Leinen',
    'Elastan',
    'Wolle',
    'Seide',
    'Viskose',
    'Denim',
    'Samt',
    'Kaschmir',
    'Gore-Tex',
    'Textil',
    'Ripstop',
    'Teddystoff',
    'Leder',
    'Wildleder',
    'Nubuck',
    'Acrylic',
    'Fleece',
    'Netzgewebe',
    'Mesh',
    'Gummi',
    'Daune',
    'Keramik',
    'Wachs',
    'Aluminium',
    'Stein',
    'Stahl',
    'Glas',
    'Bambus',
    'Kunststoff',
    'Polypropylen',
    'Gold',
    'Silber',
    'Bronze',
    'Chrom',
    'Eisen',
    'Recyceltes PET',
    'Kork',
    'Papier',
    'Synthetik-Wildleder',
    'Kunstleder',
    'Farbe',
    'Weiss',
    'Schneeweiss',
    'Cremeweiss',
    'Elfenbeinweiss',
    'Eierschalenweiss',
    'Beige',
    'Schwarz',
    'Antrahzit',
    'Tiefschwarz',
    'Mattschwarz',
    'Schiefergrau',
    'Nachtschwarz',
    'Schwarzgrau',
    'Grau',
    'Hellgrau',
    'Dunkelgrau',
    'Silbergrau',
    'Mittelgrau',
    'Mausgrau',
    'Dunkelgrau',
    'Taubengrau',
    'Betongrau',
    'Stahlgrau',
    'Zinngrau',
    'Blau',
    'Dunkelblau',
    'Hellblau',
    'Eisblau',
    'Marineblau',
    'Babyblau',
    'Himmelblau',
    'Türkisblau',
    'Royalblau',
    'Azurblau',
    'Indigoblau',
    'Kobaltblau',
    'Petrolblau',
    'Neonblau',
    'Thunfischblau',
    'Rot',
    'Dunkelrot',
    'Feuerrot',
    'Rubinrot',
    'Himbeerrot',
    'Weinrot',
    'Korallenrot',
    'Karminrot',
    'Signalrot',
    'Neonrot',
    'Blutrot',
    'Brombeere',
    'Hellrot',
    'Grün',
    'Dunkelgrün',
    'Smaragdgrün',
    'Limettengrün',
    'Hellgrün',
    'Salbeigrün',
    'Olivgrün',
    'Türkisgrün',
    'Neongrün',
    'Mintgrün',
    'Petrolgrün',
    'Soft Aloe',
    'Türkis',
    'Braun',
    'Dunkelbraun',
    'Hellbraun',
    'Rostbraun',
    'Kastanienbraun',
    'Kaffeebraun',
    'Sandbraun',
    'Nussbraun',
    'Khaki',
    'Bronze',
    'Gold',
    'Gelb',
    'Sonnengelb',
    'Zitronengelb',
    'Goldgelb',
    'Senfgelb',
    'Dunkelgelb',
    'Hellgelb',
    'Neongelb',
    'Orange',
    'Karottenorange',
    'Kürbisorange',
    'Pfirsichorange',
    'Rostorange',
    'Mandarinenorange',
    'Terrakotta',
    'Apricot',
    'Neonorange',
    'Peach',
    'Rosa',
    'Babyrosa',
    'Pastellrosa',
    'Fuchsia',
    'Altrosa',
    'Magenta',
    'Dunkelrosa',
    'Pink',
    'Lila',
    'Lavendel',
    'Flieder',
    'Pflaume',
    'Orchidee',
    'Purpur',
    'Violet',
    'Aubergin',
    'Taupe',
    'Camo',
    'Woodland Camo',
    'Desert Camo',
    'Snow Camo',
    'Urban Camo',
    'Digital Camo',
    'Tiger Stripe Camo',
    'Camouflage',
    'Floral',
    'Graugrün',
    'Metallic Rose',
    'Multicolor',
    'Silber',
  ];

  const arrayEN = [
    'Cotton',
    'Nylon',
    'Nylon Supplex',
    'Polyester',
    'Canvas',
    'Elastane',
    'Woll',
    'Silk',
    'Viscose',
    'Denim',
    'Velvet',
    'Cashmere',
    'Gore-Tex',
    'Textile',
    'Ripstop',
    'Teddy',
    'Leather',
    'Suede Leather',
    'Nubuck',
    'Acryl',
    'Fleece',
    'Mesh',
    'Mesh',
    'Rubber',
    'Down',
    'Ceramic',
    'Wax',
    'Aluminium',
    'Stone',
    'Steel',
    'Glass',
    'Bamboo',
    'Plastic',
    'Polypropylene',
    'Gold',
    'Silver',
    'Bronze',
    'Chrome',
    'Iron',
    'Recycled PET',
    'Cork',
    'Paper',
    'Synthetic suede',
    'Synthetic leather',
    'Color',
    'White',
    'Snow white',
    'Cream white',
    'Ivory white',
    'Eggshell white',
    'Wax',
    'Black',
    'Antrahzit',
    'Deep black',
    'Matt black',
    'Slate grey',
    'Night black',
    'Black Heather',
    'Grey',
    'Light grey',
    'Dark grey',
    'Silver grey',
    'Heather Grey',
    'Mouse grey',
    'Dark grey',
    'Dove grey',
    'Concrete grey',
    'Steel grey',
    'Metallic Pewter',
    'Blue',
    'Dark Navy',
    'Light blue',
    'Ice blue',
    'Navy',
    'Baby blue',
    'Sky blue',
    'Turquoise blue',
    'Royal blue',
    'Azure blue',
    'Indigo blue',
    'Cobalt blue',
    'Petrol blue',
    'Neon blue',
    'Piscine',
    'Red',
    'Dark red',
    'Fire red',
    'Ruby red',
    'Raspberry red',
    'Burgundy',
    'Coral red',
    'Crimson',
    'Infrared',
    'Neon red',
    'Blood red',
    'Blackberry',
    'Bright Crimson',
    'Green',
    'Dark green',
    'Emerald green',
    'Lime green',
    'Light green',
    'Sage green',
    'Olive green',
    'Turquoise green',
    'Aqua Green',
    'Mint',
    'Petrol',
    'Soft Aloe',
    'Teal',
    'Brown',
    'Dark brown',
    'Light brown',
    'Rust brown',
    'Chestnut brown',
    'Coffee brown',
    'Sand brown',
    'Nut brown',
    'Khaki',
    'Bronze',
    'Gold',
    'Yellow',
    'Sunshine yellow',
    'Lemon yellow',
    'Golden yellow',
    'Mustard yellow',
    'Dark yellow',
    'Light yellow',
    'Neon yellow',
    'Orange',
    'Carrot orange',
    'Pumpkin orange',
    'Peach orange',
    'Rust orange',
    'Tangerine orange',
    'Terracotta',
    'Apricot',
    'Neon orange',
    'Peach',
    'Pink',
    'Baby pink',
    'Pastel pink',
    'Fuchsia',
    'Old pink',
    'Magenta',
    'Berry',
    'Pink',
    'Purple',
    'Lavender',
    'Lilac',
    'Plum',
    'Orchid',
    'Purple',
    'Violet',
    'Eggplant',
    'Purple',
    'Camo',
    'Woodland Camo',
    'Desert Camo',
    'Snow Camo',
    'Urban Camo',
    'Digital Camo',
    'Tiger Stripe Camo',
    'Camouflage',
    'Floral',
    'Silver Mint',
    'Copper Rose',
    'Multicolor',
    'Silver',
  ];

  if (lang === 'EN') {
    isInArray = arrayDE.includes(searchString);
    inArrayPos = arrayDE.indexOf(searchString);

    if (isInArray === true) {
      finalString = arrayEN[inArrayPos];
    } else {
      finalString = searchString;
    }
  } else {
    finalString = searchString;
  }

  finalString = stringBefore + finalString;

  return finalString;
}
