import {Localizations} from '~/types/shopify';

export const countries: Localizations = {
  default: {
    language: 'DE',
    country: 'DE',
    label: 'Deutsch',
    currency: 'EUR',
  },
  '/en-de': {
    language: 'EN',
    country: 'DE',
    label: 'English',
    currency: 'EUR',
  },
};
